const projectData = [
  {
    "_id": "6573cf28527000eccfc2e1234",
    "isInHome": true,
    "released": true,
    "mainPicture": "projects/APTO-EBANO/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/APTO-EBANO/RENDER PRINCIPAL.jpg",
      "projects/APTO-EBANO/2.jpg",
      "projects/APTO-EBANO/3.jpg",
      "projects/APTO-EBANO/4.jpg",
      "projects/APTO-EBANO/15.jpg",
      "projects/APTO-EBANO/5.jpg",
      "projects/APTO-EBANO/6.jpg",
      "projects/APTO-EBANO/7.jpg",
      "projects/APTO-EBANO/8.jpg",
      "projects/APTO-EBANO/9.jpg",
      "projects/APTO-EBANO/10.jpg",
      "projects/APTO-EBANO/11.jpg",
      "projects/APTO-EBANO/12.jpg",
      "projects/APTO-EBANO/13.jpg"
    ],
    "company": "APTO ÉBANO - LG",
    "about": [
      "Um apartamento térreo compacto e moderno, com varanda estendida e uma área social integrada com ponto de destaque para a ilha em pedra preta. Ao longo do apartamento, alguns elementos pontuais foram trabalhados no amadeirado preto para compor com a pedra das bancadas, trazendo ousadia, alto contraste e modernidade ao projeto. A harmonia visual é crucial, e mesmo em um projeto ousado é importante que o espaço seja convidativo e confortável, portanto, em meio aos tons neutros, o caramelo utilizado em detalhes aquece na medida certa. A área social integrada e compacta foi pensada para atender o dia a dia do casal visando a funcionalidade e o conforto.",
      "A paleta neutra segue nos revestimentos, papel de parede e móveis em todos os outros ambientes. A suíte master agrega à paleta o verde, cor que transmite calma."
    ]
  },
  {
    "_id": "6573cf28527000eccfc2e1e7",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/CLINICA-CORUMBA/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/CLINICA-CORUMBA/RENDER PRINCIPAL.jpg",
      "projects/CLINICA-CORUMBA/3.jpg",
      "projects/CLINICA-CORUMBA/4.jpg",
      "projects/CLINICA-CORUMBA/5.jpg",
      "projects/CLINICA-CORUMBA/6.jpg",
      { url: "projects/CLINICA-CORUMBA/2.jpg", wide: true }
    ],
    "company": "CLÍNICA CORUMBÁ",
    "about": [
      "A proposta desse projeto foi modernizar a recepção nas formas e nos acabamentos, com tons claros para trazer leveza além de um dos tons da logo da clínica para reforçar a identidade da marca.",
      "O revestimento branco com relevo aplicado sobre elementos curvos reforça a organicidade do projeto, aplicado no balcão, nos brises, nos bancos e na bancada de café.",
      "Ainda nos elementos curvos, trabalhamos com boiseries que dispõem de um mural para registrar os pets pacientes da clínica. E os brises que, isolam parcialmente a espera para os tutores de felinos."
    ]
  },
  {
    "_id": "6573cf2881a8bc51b516aaf0",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/SUITE-FUNGI/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/SUITE-FUNGI/RENDER PRINCIPAL.jpg",
      "projects/SUITE-FUNGI/2.jpg",
      "projects/SUITE-FUNGI/4.jpg",
      "projects/SUITE-FUNGI/5.jpg",
      "projects/SUITE-FUNGI/6.jpg",
      "projects/SUITE-FUNGI/1.jpg",
      "projects/SUITE-FUNGI/7.jpg"
    ],
    "company": "SUÍTE FUNGI - BF",
    "about": [
      "Delicadeza em cada pequeno detalhe resultou nesse ambiente infantil leve e gracioso. Tons claros com rosa bebê trabalhados na marcenaria, no revestimento e papel de parede. Cama baixinha com um pórtico curvo em marcenaria traz um aconchego, juntamente com a iluminação suave da tela tensionada. A ludicidade se apresenta sutilmente com elementos do tema da suíte e na decoração. Mesa de estudos com espelho para uso de penteadeira."
    ]
  },
  {
    "_id": "6573cf28527000eccfc2e1dfgh",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/COZINHA-MN/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/COZINHA-MN/RENDER PRINCIPAL.jpg",
      "projects/COZINHA-MN/2.jpg",
      "projects/COZINHA-MN/3.jpg"
    ],
    "company": "COZINHA MN",
    "about": [
      "Tornando a cozinha em um espaço que se integra à sala de jantar e estar com a ilha e banquetas, ótimo para receber em casa. Bastante espaço de bancada e funcionalidade para essa cozinha, trabalhando com tons claros para ficar aconchegante."
    ]
  },
  {
    "_id": "65e3a53060749ad059a843a2",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/APTO-MONO/RENDER PRINCIPAL.png",
    "pictures": [
      "projects/APTO-MONO/RENDER PRINCIPAL.png",
      "projects/APTO-MONO/02.png",
      "projects/APTO-MONO/03.png",
      "projects/APTO-MONO/04.png",
      "projects/APTO-MONO/05.png",
      "projects/APTO-MONO/06.png",
      "projects/APTO-MONO/11.png",
      "projects/APTO-MONO/12.png",
      "projects/APTO-MONO/08.png",
      "projects/APTO-MONO/09.png",
      "projects/APTO-MONO/10.png"
    ],
    "company": "APTO MONOCROMO - SF",
    "about": [
      "A diretriz desse projeto foi a paleta monocromática no tom fendi.Utilizando esse tom em diferentes texturas, os elementos do espaço se unem de maneira coesa, criando uma sensação de harmonia e unidade visual.Aplicada na madeira, em tecidos, no mármore, na decoração, a cor fendi resultou em um ambiente simplificado e moderno.",
      "A aplicação equilibrada de texturas e padrões foram de extrema importância para enriquecer e somar a estética desse projeto monocromático.",
      "Ao entrar no apartamento, a sala de estar integrada com a sala de jantar.O muxarabi foi usado para segregar suavemente o espaço da churrasqueira.Também para dividir os espaços, portas envidraçadas isolam a cozinha.",
      "O ponto de cor vem de maneira ousada e sofisticada no mármore da cuba esculpida do lavabo.Ambiente trabalhado com muitas texturas e iluminação pontual.",
      "A suíte master segue a mesma proposta de paleta monocromática, texturas e padrões.A cabeceira da cama é composta por uma base estofada, um painel em muxarabi e um pórtico em espelho bronze.Ao lado da cama, uma penteadeira embutida na cabeceira e logo a frente da cama, um armário espelhado com TV embutida."
    ]
  },
  {
    "_id": "6573cf28527000eccfc2e18yhg",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/ESTETICA-FB/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/ESTETICA-FB/RENDER PRINCIPAL.jpg",
      "projects/ESTETICA-FB/2.jpg",
      "projects/ESTETICA-FB/3.jpg",
      "projects/ESTETICA-FB/4.jpg",
      { url: "projects/ESTETICA-FB/5.jpg", wide: true },
      "projects/ESTETICA-FB/6.jpg",
      "projects/ESTETICA-FB/7.jpg"
    ],
    "company": "ESTÉTICA FB",
    "about": [
      "Um ambiente convidativo, funcional, tranquilo e confortável para receber e atender. Essas foram as diretrizes para esse projeto de estética, na qual trabalhamos com tons neutros claros e suaves com um toque de cor.",
      "Logo na entrada, a recepção com espaço para café e poltronas para a espera. Ao lado as áreas de manicure e de maquiagem, além de uma sala de atendimentos privativa. Muita delicadeza e feminilidade nessa sala de estética linda."
    ]
  },
  {
    "_id": "6573cf28ea6ebccad18b847c",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/SUITE-OFFICE/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/SUITE-OFFICE/RENDER PRINCIPAL.jpg",
      "projects/SUITE-OFFICE/2.jpg",
      "projects/SUITE-OFFICE/3.jpg",
      "projects/SUITE-OFFICE/4.jpg"
    ],
    "company": "SUÍTE OFFICE - DT",
    "about": [
      "Uma suíte elegante e moderna em uma paleta mais escura com tons quentes, e um espaço voltado ao home office. Nesse projeto, a cama juntamente à escrivaninha são centrais ao quarto, tendo um grande destaque, por isso trabalhamos com uma base de cama e cabeceira em mármore. A iluminação aconchegante da tela tensionada vem de dentro do armário através do muxarabi trabalhado na marcenaria."
    ]
  },
  {
    "_id": "661c0860373acce8278a4538",
    "isInHome": true,
    "released": true,
    "mainPicture": "projects/SUITE-ROSE/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/SUITE-ROSE/1.jpg",
      "projects/SUITE-ROSE/2.jpg",
      "projects/SUITE-ROSE/RENDER PRINCIPAL.jpg",
      "projects/SUITE-ROSE/4.jpg",
      "projects/SUITE-ROSE/5.jpg",
      "projects/SUITE-ROSE/6.jpg",
      "projects/SUITE-ROSE/7.jpg",
      "projects/SUITE-ROSE/8.jpg"
    ],
    "company": "SUÍTE ROSE - EC",
    "about": [
      "Nos tons claros offwhite e rosa com detalhes em dourado, essa suíte transborda delicadeza, sofisticação e vaidade.",
      "Priorizamos espaço de armário e uma penteadeira muito iluminada, para acomodar todas suas roupas, bolsas, maquiagens e perfumes. As portas em reflecta bronze permitem que a iluminação interna do armário seja visível por fora, assim como todas as gavetas revestidas em couro.",
      "O quadro e o pendente reforçam a linguagem delicada do quarto, assim como a roupa de cama na tonalidade rosa.",
      "O quarto conta com um painel movie no espelho da penteadeira e com automação em toda iluminação, som ambiente, persiana e ar split.",
      "Seguindo a mesma paleta do quarto, o banheiro também é delicado e possui muito espaço de armário espelhado com iluminação. Uma linda bancada esculpida em mármore com armário inferior com puxadores personalizados no dourado. Um nicho iluminado para produtos de banho e uma parede em espelho bronze com a porta oculta que vai para o quarto."
    ]
  },
  {
    "_id": "6573cf28e041f6e8fc807e81",
    "isInHome": false,
    "released": false,
    "mainPicture": "projects/APTO-GRIS/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/APTO-GRIS/RENDER PRINCIPAL.jpg",
      "projects/APTO-GRIS/3.jpg",
      "projects/APTO-GRIS/4.jpg",
      "projects/APTO-GRIS/13.jpg",
      "projects/APTO-GRIS/7.jpg",
      "projects/APTO-GRIS/2.jpg",
      "projects/APTO-GRIS/5.jpg",
      "projects/APTO-GRIS/6.jpg",
      "projects/APTO-GRIS/8.jpg",
      "projects/APTO-GRIS/14.jpg",
      "projects/APTO-GRIS/9.jpg",
      "projects/APTO-GRIS/10.jpg",
      "projects/APTO-GRIS/11.jpg",
      "projects/APTO-GRIS/12.jpg",
    ],
    "company": "APTO GRIS - OC",
    "about": [
      "Explorando a paleta de tons quentes e escuros, esse projeto transmite uma atmosfera calorosa e de aconchego, juntamente com uma elegância e contemporaneidade dada pelas formas e integração de toda a área social. A iluminação fortalece a linguagem de aconchego sendo mais pontual, perimetral e indireta.",
      "Logo no hall de acesso uma caixa de vidro com um vaso vietnamita com galhos secos isola parcialmente a visão da cozinha ao entrar no apartamento.",
      "No gourmet uma porta oculta a churrasqueira, trazendo simetria no móvel em marcenaria. Tanto no gourmet quanto na cozinha, a mesa de jantar envolve a ilha, mesclando formas orgânicas e curvas, transformando-os em espaços descontraídos para receber.",
      "A cozinha dispõe de uma adega totalmente iluminada que comporta 192 garrafas expostas, além das adegas refrigeradas, especialmente pensada para fãs de vinhos. Além de um louceiro envidraçado que completa a elegância agregada à funcionalidade do espaço.",
      "O lavabo segue a mesma linha estética da área social, além de ter um piso em seixos e um forro iluminado em tela tensionada."
    ],
    "suitePics": [
      "projects/APTO-GRIS/15.jpg",
      "projects/APTO-GRIS/16.jpg",
      {url: "projects/APTO-GRIS/17.jpg", wide: true},
      "projects/APTO-GRIS/18.jpg",
      "projects/APTO-GRIS/19.jpg",
      "projects/APTO-GRIS/20.jpg"
    ],
    "suiteTitle": "SUÍTE",
    "suiteAbout": [
      "Na suíte master trouxemos um ar mais rústico, seguindo a atmosfera aconchegante de todo o projeto. O revestimento orgânico usado no piso ditou a proposta dessa ampla suíte, usado juntamente com o tom mais quente de madeira da área social.",
      "A cuba integrada com a área do closet permite usos simultâneos com a área de banho e bacia sanitária."
    ]
  },
  {
    "_id": "6573cf28527000eccfc2e1e8",
    "isInHome": false,
    "released": false,
    "mainPicture": "projects/SUITE-BEAR/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/SUITE-BEAR/RENDER PRINCIPAL.jpg",
      "projects/SUITE-BEAR/2.jpg",
      "projects/SUITE-BEAR/3.jpg",
      "projects/SUITE-BEAR/4.jpg",
      "projects/SUITE-BEAR/6.jpg",
      "projects/SUITE-BEAR/5.jpg",
    ],
    "company": "SUÍTE BEAR - LF",
    "about": [
      "Tons suaves para transmitir calma em meio a atmosfera lúdica desse quartinho personalizado. Uma suíte funcional, estimulante e segura, com a cama baixa e um espaço de estudos para acompanhar o pequeno por anos. O banheiro segue a paleta com um toque lúdico nas pastilhas orgânicas."
    ]
  },
  {
    "_id": "6573cf28c1d3ec64cf7b3b08",
    "isInHome": false,
    "released": false,
    "mainPicture": "projects/APTO-NSEI/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/APTO-NSEI/RENDER PRINCIPAL.jpg",
      "projects/APTO-NSEI/1.jpg",
      "projects/APTO-NSEI/2.jpg",
      "projects/APTO-NSEI/3.jpg",
      "projects/APTO-NSEI/5.jpg",
      "projects/APTO-NSEI/6.jpg",
      "projects/APTO-NSEI/7.jpg",
      "projects/APTO-NSEI/8.jpg",
      "projects/APTO-NSEI/9.jpg",
      "projects/APTO-NSEI/10.jpg",
      "projects/APTO-NSEI/17.jpg",
      "projects/APTO-NSEI/13.jpg",
      "projects/APTO-NSEI/14.jpg",
      "projects/APTO-NSEI/15.jpg",
      "projects/APTO-NSEI/11.jpg",
      "projects/APTO-NSEI/12.jpg",
      "projects/APTO-NSEI/16.jpg"
    ],
    "company": "APTO VERT - JN",
    "about": [
      "Integração e sofisticação foram as chaves para esse projeto nos tons e na amplitude dessa área social, que pode ser inteiramente vista de qualquer cantinho, devido a toda a permeabilidade visual que essa planta permitiu. O diálogo entre os espaços foi feito além de fisicamente, esteticamente através dos materiais, que se repetem em toda a extensão do projeto.",
      "A leveza do mármore, juntamente com o peso da pedra rústica se equilibram, assim como a paleta de tons claros contrasta com os detalhes em preto.",
      "Ainda mantendo a integração entre os ambientes, os brises em muxarabi são divisórias sutis que trazem um toque de bossa que aquece o projeto paralelamente com a madeira.",
      "Um projeto pensado para receber, com áreas de estar, gourmet equipado com churrasqueira, chopeira, adega, cervejeira, grill, cooktop de apoio além de uma bancada com barzinho. Expor as garrafas de vinho nessa adega suspensa se torna uma arte na sala de jantar.",
      "A cozinha separada por portas de correr espelhadas é esteticamente limpa e leve, com eletrodomésticos tecnológicos em formato de galeria e refrigerador oculto que se contrapõem com o peso da ilha no mármore esculpido.",
      "O diálogo entre os materiais segue no lavabo e são destacados através da iluminação aconchegante da tela tensionada em toda a parede da bancada esculpida."
    ],
    "suitePics": [
      "projects/APTO-NSEI/18.jpg",
      { url: "projects/APTO-NSEI/19.jpg", wide: true },
      "projects/APTO-NSEI/20.jpg",
      "projects/APTO-NSEI/21.jpg",
      "projects/APTO-NSEI/22.jpg",
      { url: "projects/APTO-NSEI/23.jpg", wide: true },
      "projects/APTO-NSEI/24.jpg",
      "projects/APTO-NSEI/25.jpg",
      { url: "projects/APTO-NSEI/26.jpg", wide: true },
      "projects/APTO-NSEI/27.jpg"
    ],
    "suiteTitle": "SUÍTE",
    "suiteAbout": [
      "A suíte master é uma extensão estética da área social, o mesmo mármore georgette é trabalhado com leveza nesse ambiente todo integrado, moderno e amplo.",
      "Um closet totalmente envidraçado com iluminação em tela tensionada e uma ilha toda em mármore com bancada para maquiagem e cuba dupla.",
      "Ao lado da cama, um cantinho para descanso e leitura com lareira.",
      "Banheiro integrado com área de banheira de imersão e duchas abertas. Modernidade e elegância em cada detalhe."
    ]
  }
]

export const projectsInHome: Array<any> = projectData.filter(item=>item.isInHome)
export const allProjects: Array<any> = projectData

export const getProjectDetails = (projectId?: string)=> projectId? projectData.filter(item=>item._id === projectId)[0]: null

export type ProjectType = {
  _id: string;
  isInHome: boolean;
  mainPicture: string;
  pictures: string[] | {url: string, wide: boolean}[];
  company: string;
  about: string;
  suiteAbout?: string[];
  suiteTtitle?: string;
  suitePics?: string[] | { url: string, wide: boolean }[];
  released: boolean;
}