import { BsWhatsapp } from "react-icons/bs"
import Button from "react-bootstrap/Button"

export const FirstPage = () => (<div id="home" className="container">
  <div className="pt-4">
    <p className="text-center text-lg-start h3">
      Arquitetura contemporânea e personalizada que traduz sua essência.
    </p>
  </div>
  <div className="d-flex justify-content-center justify-content-lg-start pt-3 pb-3">
    <Button variant="amazonia" size="lg" href="https://api.whatsapp.com/send?phone=5543999000715" target="_blank">
      Entre em contato <BsWhatsapp />
    </Button>
  </div>
</div>)