import Image from "react-bootstrap/Image"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ProjectType, allProjects } from '../../data';

export const Projects = () => {
  const resolveMediaPath = (path: string) => (`${process.env.PUBLIC_URL}/img/${path}`)
  return (<div className='projects-page container ps-0 pe-0'>
    {/* <OfficeGoal /> */}
    <Row>
      <Col md="12" lg="12">
        <p className='fs-2 mt-3 ms-2'>
          Projetos
        </p>
      </Col>
    </Row>
    <Row>
      {allProjects.map((project: ProjectType) => (
        <Col md="12" lg="6" className={project.released ? "p-0 project-item" : "p-0 project-item unreleased"} id={project._id} key={`project-${project._id}`}>
          <a href={project.released ? 'projects/' + project._id : "javascript:void(0)"}>
            {project.released ? <div className="project-title ps-3">{project.company}</div>: <div className="project-title ps-3">Em Breve</div>}
            <div className="project-image-wrapper">
              <Image src={resolveMediaPath(project.mainPicture)} loading="lazy" />
            </div>
          </a>
        </Col>
      ))}
    </Row>
  </div>)
}