// import { About } from "./About";
import { Contact } from "../../components/Contact";
import { FirstPage } from "./FirstPage";
import { Projects } from "./Projects";

export const Home = () => (
  <>
    <FirstPage />
    {/* <About /> */}
    <Projects />
    <Contact />
  </>
)