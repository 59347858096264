import { useCallback, useState } from "react";

import { useParams } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Image from "react-bootstrap/Image"
import Row from 'react-bootstrap/Row';
import ImageViewer from 'react-simple-image-viewer';
import Button from "react-bootstrap/Button"
import { LinkContainer } from 'react-router-bootstrap'

import { getProjectDetails } from '../../data';

export const ProjectDetails = () => {
  const { projectId } = useParams();
  const projectDetails = getProjectDetails(projectId);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [currentSuiteImage, setCurrentSuiteImage] = useState(0);
  const [isSuiteViewerOpen, setIsSuiteViewerOpen] = useState(false);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const openSuiteImageViewer = useCallback((index: number) => {
    setCurrentSuiteImage(index);
    setIsSuiteViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setCurrentSuiteImage(0);
    setIsViewerOpen(false);
    setIsSuiteViewerOpen(false);
  };

  const resolveMediaPath = (path: string | any) => path.url? (
    {url:`${process.env.PUBLIC_URL}/img/${path.url}`, wide: path.wide}
  ): (
    { url: `${process.env.PUBLIC_URL}/img/${path}`, wide: false }
  )

  const images = projectDetails && projectDetails.pictures.map((img: string | any) => resolveMediaPath(img));
  const suiteImages = projectDetails && projectDetails.suitePics?.map((img: string | any) => resolveMediaPath(img));

  return (<>
    <div className='project-details container ps-0 pe-0'>
      {projectDetails && <>
        <Row>
          <Col md="12" lg="12">
            <p className='fs-2 mt-3 project-title'>
              {projectDetails.company}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="12">
            <div className="project-description">
              {projectDetails.about.map(paragraphs => (<p className='text-justify mb-0'>
                {paragraphs}
              </p>))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="12">
            <p className='text-justify fs-6 fst-italic project-note mt-3'>
              Clique nas imagens para ampliá-las
            </p>
          </Col>
        </Row>
        <Row>
          {images && images.map((img: any, index) => (
            <Col md="6" lg="3" className="p-0 project-item">
              <div className='image-wrapper'>
                <Image fluid={!img.wide}
                  className={img.wide ? 'wide-img' : ""}
                  onClick={() => openImageViewer(index)}
                  src={img.url}
                  loading="lazy"
                />
              </div>
            </Col>
          ))}
          {isViewerOpen && (
            <ImageViewer
              src={images?.map(img => img.url) || []}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}
        </Row>
        {projectDetails.suiteAbout && <div>
          <Row>
            <Col md="12" lg="12">
              <p className='fs-2 mt-3 project-title'>
                {projectDetails.suiteTitle}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12">
              <div className="project-description">
                {projectDetails.suiteAbout.map(paragraphs => (<p className='text-justify mb-0'>
                  {paragraphs}
                </p>))}
              </div>
            </Col>
          </Row>
          <Row>
            {suiteImages && suiteImages.map((img: any, index) => (
              <Col md="6" lg="3" className="p-0 project-item">
                <div className='image-wrapper'>
                  <Image fluid={!img.wide}
                    className={img.wide? 'wide-img': ""}
                    onClick={() => openSuiteImageViewer(index)}
                    src={img.url}
                    loading="lazy"
                  />
                </div>
              </Col>
            ))}
            {isSuiteViewerOpen && (
              <ImageViewer
                src={suiteImages?.map(img => img.url) || []}
                currentIndex={currentSuiteImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </Row>
        </div>}
      </>}
    </div>
    <div className="mt-4 d-grid gap-2 w-350">
      <LinkContainer to="/projects">
        <Button variant="amazonia" className="block" target="_blank">
          Voltar
        </Button>
      </LinkContainer>
    </div>
  </>)
}