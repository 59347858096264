import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsWhatsapp } from "react-icons/bs"
import Button from "react-bootstrap/Button"

import OfficeGoal from "../../components/OfficeGoal";

export const About = () => (<div className="container">
  <Row id="aboutme">
    <Col md="12" lg="12">
      <p className='fs-2 mt-3 ms-2'>
        Sobre mim
      </p>
    </Col>
  </Row>
  <Row>
    <Col md={{ span: '12', order: "first" }} lg={{ span: '9', order: "last" }}>
      <p className="text-justify">
        Tenho 26 anos e me formei como arquiteta e urbanista pela Universidade Estadual de Londrina em 2021.
      </p>
      <p className="text-justify">
        Atuo como arquiteta desde 2019 com projetos autorais.
      </p>
      <p className="text-justify">
        Minha paixão é transformar ambientes para que reflitam a personalidade e atendam às necessidades dos clientes em um projeto bem planejado e funcional.
      </p>
      <p className="text-justify">
        O indivíduo é o centro e o propósito da arquitetura. Cada ambiente projetado abriga junto a nós, nossas mentes, nossos sonhos, nossos desejos e nossas memórias.
      </p>
      <p className="text-justify">
        Me coloco no lugar do cliente com sensibilidade para sentir como o usuário vai vivenciar cada espaço, busco me imaginar usufruindo cada cantinho do projeto para trazer a maior qualidade de vida possível dentro das expectativas e possibilidades do cliente. Essa abordagem centrada no usuário visa além da estética, maneiras de melhorar seu bem-estar e a sua qualidade de vida.
      </p>
      <p className="text-justify">
        Acredito na importância de criar espaços que não apenas atendam às necessidades funcionais, mas também ressoem com a essência e a alma das pessoas que os habitam.
      </p>
    </Col>
    <Col md='12' lg='3' className="d-flex justify-content-center align-items-center justify-content-lg-center">
      <div>
        <Image className="about-pic" src={process.env.PUBLIC_URL + 'img/whoami.jpg'} rounded fluid />
      </div>
    </Col>
  </Row>
  <OfficeGoal />
  <Row>
    <Col md="12" lg="12">
        <div className='pt-3 d-flex justify-content-center'>
          <Button variant="amazonia" size="lg" href="https://api.whatsapp.com/send?phone=5543999000715" target="_blank">
            Entre em contato <BsWhatsapp />
          </Button>
        </div>
    </Col>
  </Row>
</div>)