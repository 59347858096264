import { BsWhatsapp, BsInstagram } from "react-icons/bs"
import Button from "react-bootstrap/Button"
import Col from 'react-bootstrap/Col';
import emailjs from '@emailjs/browser';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';

import * as formik from 'formik';
import * as yup from 'yup';
import { useState } from "react";

export const Contact = () => {

  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const toggleToast = () => setToast(!toast);

  const { Formik } = formik;

  const schema = yup.object().shape({
    name: yup.string().nullable().required("este campo é obrigatório"),
    phone: yup.string().nullable().matches(/^\(([0-9]{2})\)([0-9]{4,5})-([0-9]{3,4})$/, "Telefone inválido").required("este campo é obrigatório"),
    email: yup.string().nullable().email("O e-mail digitado é inválido").required("este campo é obrigatório"),
    message: yup.string().nullable().required("este campo é obrigatório")
  });

  const sendEmail = (form: any, actions: any) => {
    setLoading(true);
    emailjs.send((process.env.REACT_APP_EJS_SVC_ID || ''), (process.env.REACT_APP_EJS_T_ID || ''), form, process.env.REACT_APP_EJS_PKEY)
      .then((result) => {
        setLoading(false)
        setToast(true);
        actions.resetForm();
        setTimeout(() => {
          setToast(false);
        }, 7000)
      }, (error) => {
        setLoading(false);
      });
  }
  return (<div id="contact" className="container">
    <Row>
      <Col md="12" lg="12">
        <p className='fs-2 mt-3 ms-2'>
          Contato
        </p>
      </Col>
    </Row>
    <div>
      <p className="text-justify">
        Entre em contato preenchendo o formulário abaixo.
      </p>
    </div>
    <>
      <Formik
        validationSchema={schema}
        onSubmit={(form, actions) => { sendEmail(form, actions) }}
        initialValues={{
          name: '',
          phone: '',
          email: '',
          message: ''
        }}
      >
        {({ handleSubmit, handleChange, values, errors, handleReset, isValid, touched }) => (
          <Form noValidate onSubmit={handleSubmit} onReset={handleReset}>
            <Row>
              <Col md="12" lg="4">
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Nome *</Form.Label>
                  <Form.Control disabled={loading} required type="text" isInvalid={!!errors.name} isValid={touched.name && !errors.name} onChange={handleChange} value={values.name} name="name" placeholder="Nome" />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="12" lg="4">
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>E-mail *</Form.Label>
                  <Form.Control disabled={loading} required type="email" isInvalid={!!errors.email} isValid={touched.email && !errors.email} onChange={handleChange} value={values.email} name="email" placeholder="Digite seu e-mail" />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="12" lg="4">
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Telefone *</Form.Label>
                  <Form.Control
                    as={InputMask}
                    mask="(99)99999-9999"
                    required type="text"
                    isInvalid={!!errors.phone} isValid={touched.phone && !errors.phone} onChange={handleChange} value={values.phone} name="phone"
                    placeholder="(XX)12345-6789" />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="12" lg="12">
                <Form.Group className="mb-3" controlId="message">
                  <Form.Label>Breve descrição do seu projeto *</Form.Label>
                  <Form.Control disabled={loading} required as="textarea" isInvalid={!!errors.message} isValid={touched.message && !errors.message} onChange={handleChange} value={values.message} name="message" placeholder="Descrição" />
                  <Form.Control.Feedback type="invalid">
                    {errors.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-center mt-3 mb-3">
              <Toast show={toast} onClose={toggleToast} animation={true}>
                <Toast.Header>
                  <strong className="me-auto">E-mail</strong>
                </Toast.Header>
                <Toast.Body>E-mail enviado com sucesso!</Toast.Body>
              </Toast>
            </div>
            <div className="d-flex justify-content-center mb-3">
              <Button className="me-2" variant="capuccino" type="reset" disabled={loading}>
                Redefinir
              </Button>
              <Button className="ms-2 me-2" variant="amazonia" type="submit" disabled={!isValid || loading}>
                Enviar
              </Button>
              {loading && <Spinner className="ms-2" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>}
            </div>
          </Form>
        )}
      </Formik>
    </>
    <div className="pt-2">
      Redes Sociais:
      <Button className="ms-3 me-1" variant="outline-capuccino" href="https://api.whatsapp.com/send?phone=5543999000715" target="_blank" >
        <BsWhatsapp />
      </Button>
      <Button className="ms-1 me-1" variant="outline-capuccino" href="https://instagram.com/leticianunesarquiteta" target="_blank" >
        <BsInstagram />
      </Button>
    </div>
  </div>)
}