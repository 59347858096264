import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from 'react-router-bootstrap'
import { BsWhatsapp } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";

const Header = () => (<>
  <Navbar bg="creme" expand="lg" className="bg-body-creme" sticky="top">
    <Container fluid>
      <LinkContainer to="/">
        <Navbar.Brand className="ps-2">
          <img
            alt="Leticia Nunes"
            src={"/img/logo-header.png"}
            height="50"
            className="d-none d-lg-block d-xl-block d-inline-block align-top"
          />
          <img
            alt="Leticia Nunes"
            src={"/img/logo-mini.png"}
            height="50"
            className="d-lg-none d-xl-none d-xs-block d-sm-block d-md-block align-top"
          />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="d-flex justify-content-evenly w-100">
          <LinkContainer to="/">
            <Nav.Link className="link-amazonia d-flex justify-content-end">Início</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/about">
            <Nav.Link className="link-amazonia d-flex justify-content-end">Sobre mim</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/projects">
            <Nav.Link className="link-amazonia d-flex justify-content-end">Projetos</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/services">
            <Nav.Link className="link-amazonia d-flex justify-content-end">Serviços</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/contact">
            <Nav.Link className="link-amazonia d-flex justify-content-end">Contato</Nav.Link>
          </LinkContainer>
        </Nav>
        <div className="d-flex justify-content-end mx-lg-4">
          <Nav.Link href="https://www.instagram.com/leticianunesarquiteta" target="_blank" className="link-amazonia d-flex justify-content-end ms-1 me-1"><BsInstagram /></Nav.Link>
          <Nav.Link href="https://api.whatsapp.com/send?phone=5543999000715" target="_blank" className="link-amazonia d-flex justify-content-end ms-1"><BsWhatsapp /></Nav.Link>
        </div>
      </Navbar.Collapse>
    </Container>
  </Navbar>
</>
)

export default Header;