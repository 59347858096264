import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import { About, Contact, Home, ProjectDetails, Projects, ErrorPage, Services } from './routes';
import Root from './routes/root';

import './index.scss';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />
      },
      {
        path: "projects",
        element: <Projects />,
        errorElement: <ErrorPage />
      },
      {
        path: "projects/:projectId",
        element: <ProjectDetails />,
        errorElement: <ErrorPage />
      },
      {
        path: "contact",
        errorElement: <ErrorPage />,
        element: <Contact />
      },
      {
        path: "services",
        errorElement: <ErrorPage />,
        element: <Services />
      },
      {
        path: "about",
        errorElement: <ErrorPage />,
        element: <About />
      }
    ]
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
