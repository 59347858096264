import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const OfficeGoal = () => (<>
  <Row>
    <Col md="12" lg="12">
      <p className='fs-2 mt-3 ms-2'>
        Objetivo do Escritório
      </p>
    </Col>
  </Row>
  <Row>
    <Col md="12" lg="12">
      <p className="text-justify">
        Foco em criar uma forma arquitetônica contemporânea e atemporal nas linhas e cores, trazendo conforto e funcionalidade para qualquer tipo de espaço.
      </p>
      <p className="text-justify">
        Busco traduzir suas necessidades e solucionar suas queixas e desafios diários com uma arquitetura contemporânea e funcional, sempre de forma personalizada para melhorar seu bem-estar e experiência geral no 'morar'.
      </p>
    </Col>
  </Row>
</>)

export default OfficeGoal;