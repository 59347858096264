import { useRouteError } from "react-router-dom";
import Card from "react-bootstrap/Card"

export const ErrorPage = () => {
  const error: any = useRouteError();
  console.error(error);

  return (
    <Card id="error-page">
      <Card.Body>
        <Card.Title className="fs-2">
          Opa!
        </Card.Title>
        <div>
          <p>Algo deu errado.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </Card.Body>
    </Card>);
}