import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Root() {
  return (<>
    <Header />
    <div className="content-container">
      <Outlet />
    </div>
    <Footer />
  </>)
}