import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button"
import { LinkContainer } from 'react-router-bootstrap'

import { ProjectType, projectsInHome } from '../../data';


export const Projects = () => {
  const resolveMediaPath = (path: string) => (`${process.env.PUBLIC_URL}/img/${path}`)
  return (<div id="projects" className="container">
    <Row>
      <Col md="12" lg="12">
        <p className='fs-2 mt-3 ms-2'>
          Projetos
        </p>
      </Col>
    </Row>
    {projectsInHome.map((project: ProjectType) => (
      <div className="project-item-wrapper" id={project._id} key={`pj-${project._id}`}>
        <a href={'projects/' + project._id}>
          <div className="project-title ps-3">{project.company}</div>
          <div className="project-image-wrapper">
            <Image src={resolveMediaPath(project.mainPicture)} loading="lazy"/>
          </div>
        </a>
      </div>
    ))}
    <div className="d-flex justify-content-center pt-3 m-2">
      <LinkContainer to="/projects">
        <Button variant="outline-amazonia" target="_blank">
          Confira meus outros projetos
        </Button>
      </LinkContainer>
    </div>
  </div>)
}