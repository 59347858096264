import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsWhatsapp } from "react-icons/bs"
import Button from "react-bootstrap/Button"


export const Services = () => (<div className="container">
  <Row>
    <Col md="12" lg="12">
      <p className='fs-2 mt-3 ms-2'>
        Arquitetônico
      </p>
    </Col>
  </Row>
  <Row>
    <Col md="12" lg="12">
      <ol>
        <li>Briefing</li>
        <li>Análise do terreno e recebimento do levantamento topográfico</li>
        <li>Criação do layout e estudo volumétrico</li>
        <li>Alterações e aprovação</li>
        <li>Projeto legal e executivo</li>
        <li>Projetos complementares</li>
        <li>Compatibilização de projetos</li>
        <li>Entrega do executivo</li>
        <li>Visita às lojas</li>
        <li>Assessoria durante a obra</li>
        <li>Decoração e fotos</li>
      </ol>
    </Col>
  </Row>

  <Row>
    <Col md="12" lg="12">
      <p className='fs-2 mt-3 ms-2'>
        Interiores
      </p>
    </Col>
  </Row>
  <Row>
    <Col md="12" lg="12">
      <ol>
        <li>Recebimento de plantas técnicas</li>
        <li>Briefing</li>
        <li>Levantamento fotográfico e de medidas</li>
        <li>Apresentação do projeto</li>
        <li>Alterações e aprovação</li>
        <li>Detalhamento técnico</li>
        <li>Visita às lojas</li>
        <li>Assessoria durante a obra</li>
        <li>Decoração e fotos</li>
      </ol>
    </Col>
  </Row>

  <Row>
    <Col md="12" lg="12">
      <p className='fs-2 mt-3 ms-2'>
        Completo (Arquitetônico + Interiores)
      </p>
    </Col>
  </Row>
  <Row>
    <Col md="12" lg="12">
      <ol>
        <li>Briefing</li>
        <li>Análise do terreno e recebimento do levantamento topográfico</li>
        <li>Criação do layout e estudo volumétrico</li>
        <li>Alterações e aprovação</li>
        <li>Projeto legal e executivo</li>
        <li>Projetos complementares</li>
        <li>Compatibilização de projetos</li>
        <li>Entrega do executivo</li>
        <li>Visita às lojas</li>
        <li>Início da assessoria</li>
        <li>Briefing de interiores</li>
        <li>Apresentação do projeto</li>
        <li>Alterações e aprovação</li>
        <li>Detalhamento técnico</li>
        <li>Visita às lojas</li>
        <li>Assessoria durante a obra</li>
        <li>Decoração e fotos</li>
      </ol>
    </Col>
  </Row>
  <Row>
    <Col md="12" lg="12">
      <div className='pt-3'>
        <p className='fs-5 pe-2 text-center'>
          Ficou com alguma dúvida? Para mais informações:
        </p>
        <div className='d-flex justify-content-center'>
          <Button variant="amazonia" size="lg" href="https://api.whatsapp.com/send?phone=5543999000715" target="_blank">
            Entre em contato <BsWhatsapp />
          </Button>
        </div>
      </div>
    </Col>
  </Row>
</div>
)